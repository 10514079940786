import { Component } from '@angular/core';
import { FeedbackService } from '../../services/feedback.service';
import { TranslationService } from '../../services/translation.service';
import { MatDialog } from '@angular/material/dialog';
import { FeedbackDialogComponent } from '../../feedback/feedback-dialog/feedback-dialog.component';

@Component({
  selector: 'portal-paypal-payment-cancelled',
  templateUrl: './paypal-payment-cancelled.component.html',
  styleUrls: ['./paypal-payment-cancelled.component.scss'],
})
export class PayPalPaymentCancelledComponent {
  constructor(
    public translationService: TranslationService,
    public feedbackService: FeedbackService,
    private _matDialog: MatDialog
  ) {}

  public openFeedbackDialog(): void {
    FeedbackDialogComponent.openFeedbackDialog(this.feedbackService, this._matDialog, {
      dialogHeaderText: this.translationService.translations.headers.PayPal.PaymentFeedback.toString(),
      feedbackInfoText: null,
      feedbackInfoSubtext: null,
      feedbackCategoryId: 4,
    });
  }

  public openRequestSupportDialog(): void {
    FeedbackDialogComponent.openFeedbackDialog(this.feedbackService, this._matDialog, {
      headerIcon: 'enthus-contact_support',
      dialogHeaderText: this.translationService.translations.headers.PayPal.RequestSupport.toString(),
      feedbackInfoText: null,
      feedbackInfoSubtext: null,
      feedbackCategoryId: 4,
      showAlternativeContactOptions: true,
    });
  }
}
