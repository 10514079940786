<mat-form-field>
  <mat-label>{{ translationService.translations.labels.ReferenceNo }}</mat-label>
  <input [attr.data-test-selector]="'referenceNoInput'" [formControl]="$any(checkoutDetailsForm.get('referenceNo'))" [maxLength]="50" matInput />
  @if (checkoutDetailsForm.get('referenceNo').hasError('saveFailed')) {
    <mat-error>{{ translationService.translations.error.SaveReferenceNoFailed }}</mat-error>
  }
  <mat-hint>{{ translationService.translations.information.ReferenceNoInfo }}</mat-hint>

  @if (
    checkoutDetailsForm.get('referenceNo').touched &&
    referenceValueSaved &&
    (cartService.cart$ | async).CartReference === checkoutDetailsForm.get('referenceNo').value &&
    ((referenceNoControlLoading$ | async) === false || !checkoutDetailsForm.get('referenceNo').dirty)
  ) {
    <div matIconSuffix>
      <mat-icon [svgIcon]="'enthus-done'"></mat-icon>
    </div>
  }
  @if (checkoutDetailsForm.get('referenceNo').hasError('saveFailed')) {
    <button
      matIconSuffix
      mat-button
      class="save-remark"
      (click)="checkoutDetailsForm.get('referenceNo').setValue(checkoutDetailsForm.get('referenceNo').value)"
    >
      <mat-icon [svgIcon]="'enthus-save'"></mat-icon>
    </button>
  }
  @if ((referenceNoControlLoading$ | async) === true && checkoutDetailsForm.get('referenceNo').dirty && checkoutDetailsForm.get('referenceNo').valid) {
    <mat-progress-spinner mode="indeterminate" diameter="20" matIconSuffix></mat-progress-spinner>
  }
</mat-form-field>
@if (createAsOrder) {
  <div class="preferred-delivery-date" [class.mobile]="(appService.isMobile$ | async) === true" [attr.data-test-selector]="'preferredDeliveryDate'">
    <mat-form-field class="specification">
      <mat-label>{{ translationService.translations.labels.DeliverySpecifications }}</mat-label>
      <mat-select
        [formControl]="$any(checkoutDetailsForm.get('deliveryDateGroup')).get('deliveryDateTypeControl')"
        [errorStateMatcher]="immediateMatcher"
        [attr.data-test-selector]="'preferredDeliveryDateTypeSelect'"
      >
        @for (deliveryDateDropDownValue of deliveryDateDropDownValues; track $index) {
          <mat-option [value]="deliveryDateDropDownValue.DeliveryDateTypeID">
            {{ deliveryDateDropDownValue.DeliveryDateSpecification[translationService.locale] }}
          </mat-option>
        }
      </mat-select>
      @if (checkoutDetailsForm.get('deliveryDateGroup').get('deliveryDateTypeControl').hasError('saveFailed')) {
        <mat-error>{{ translationService.translations.error.SavePreferredDeliverySpecificationFailed }}</mat-error>
      } @else if (checkoutDetailsForm.get('deliveryDateGroup').get('deliveryDateTypeControl').hasError('requiredCombination')) {
        <mat-error>{{ translationService.translations.error.RequiredDeliverySpecification }}</mat-error>
      }
      @if ((appService.isMobile$ | async) === false) {
        <mat-hint>{{ translationService.translations.action.PreferredDeliveryDate }}</mat-hint>
      }
    </mat-form-field>
    <mat-form-field class="datepicker">
      <mat-label>{{ translationService.translations.feedback.Date }}</mat-label>
      <input
        matInput
        [formControl]="$any(checkoutDetailsForm.get('deliveryDateGroup')).get('deliveryDateControl')"
        [matDatepicker]="$any(datepicker)"
        (click)="datepicker.open()"
        [min]="dateTomorrow"
        [attr.data-test-selector]="'preferredDeliveryDateDatepicker'"
        readonly
      />
      <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
      <mat-datepicker [color]="'accent'" [touchUi]="(appService.isMobile$ | async) === true && !appService.desktopIsForced" #datepicker>
        <mat-datepicker-actions>
          <button mat-button matDatepickerCancel (click)="checkoutDetailsForm.get('deliveryDateGroup').get('deliveryDateControl').reset()">
            {{ translationService.translations.action.Clear }}
          </button>
          <button mat-raised-button color="accent" matDatepickerApply>{{ translationService.translations.action.Save }}</button>
        </mat-datepicker-actions>
      </mat-datepicker>
      @if (checkoutDetailsForm.get('deliveryDateGroup').get('deliveryDateControl').hasError('saveFailed')) {
        <mat-error>{{ translationService.translations.error.SavePreferredDeliveryDateFailed }}</mat-error>
      }
      @if ((appService.isMobile$ | async) === true) {
        <mat-hint>{{ translationService.translations.action.PreferredDeliveryDate }}</mat-hint>
      }
    </mat-form-field>
  </div>
}
<mat-form-field class="checkout-description-and-reference-no-info">
  <mat-label>{{ translationService.translations.labels.DocumentDescription }}</mat-label>
  <input matInput [formControl]="$any(checkoutDetailsForm.get('description'))" [maxLength]="100" [attr.data-test-selector]="'descriptionInput'" />
  @if (checkoutDetailsForm.get('description').hasError('saveFailed')) {
    <mat-error>{{ translationService.translations.error.SaveDescriptionFailed }}</mat-error>
  }
  <mat-hint>{{ translationService.translations.information.DocumentDescription }}</mat-hint>

  @if (
    checkoutDetailsForm.get('description').touched &&
    descriptionValueSaved &&
    (cartService.cart$ | async).CartDescription === checkoutDetailsForm.get('description').value &&
    ((descriptionControlLoading$ | async) === false || !checkoutDetailsForm.get('description').dirty)
  ) {
    <div matIconSuffix>
      <mat-icon [svgIcon]="'enthus-done'"></mat-icon>
    </div>
  }
  @if (checkoutDetailsForm.get('description').hasError('saveFailed')) {
    <button
      matIconSuffix
      mat-button
      class="save-remark"
      (click)="checkoutDetailsForm.get('description').setValue(checkoutDetailsForm.get('description').value)"
    >
      <mat-icon [svgIcon]="'enthus-save'"></mat-icon>
    </button>
  }
  @if ((descriptionControlLoading$ | async) === true && checkoutDetailsForm.get('description').dirty && checkoutDetailsForm.get('description').valid) {
    <mat-progress-spinner mode="indeterminate" diameter="20" matIconSuffix></mat-progress-spinner>
  }
</mat-form-field>
<mat-form-field class="message-textbox">
  <mat-label>{{ translationService.translations.labels.Message }}</mat-label>
  <textarea
    matInput
    [rows]="5"
    [formControl]="$any(checkoutDetailsForm.get('remark'))"
    [errorStateMatcher]="showErrorInstantErrorStateMatcher"
    [attr.data-test-selector]="'remarkTextarea'"
  ></textarea>
  <mat-hint>{{ translationService.translations.labels.AddMessageForEnthus }}</mat-hint>
  @if (checkoutDetailsForm.get('remark').hasError('saveFailed')) {
    <mat-error>{{ translationService.translations.error.SaveRemarkFailed }}</mat-error>
  }

  @if (
    checkoutDetailsForm.get('remark').touched &&
    remarkValueSaved &&
    (cartService.cart$ | async).CartRemark === checkoutDetailsForm.get('remark').value &&
    !checkoutDetailsForm.get('remark').invalid &&
    ((remarkControlLoading$ | async) === false || !checkoutDetailsForm.get('remark').dirty)
  ) {
    <div matIconSuffix>
      <mat-icon [svgIcon]="'enthus-done'"></mat-icon>
    </div>
  }
  @if (checkoutDetailsForm.get('remark').hasError('saveFailed')) {
    <button matIconSuffix mat-button class="save-remark" (click)="checkoutDetailsForm.get('remark').setValue(checkoutDetailsForm.get('remark').value)">
      <mat-icon [svgIcon]="'enthus-save'"></mat-icon>
    </button>
  }
  @if ((remarkControlLoading$ | async) === true && checkoutDetailsForm.get('remark').dirty && checkoutDetailsForm.get('remark').valid) {
    <mat-progress-spinner mode="indeterminate" diameter="20" matIconSuffix></mat-progress-spinner>
  }
</mat-form-field>
