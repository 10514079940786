<mat-form-field floatLabel="auto" class="hide-form-changes" color="accent">
  <mat-label>{{ translationService.translations.feedback.Options.Label }}</mat-label>
  <mat-select [formControl]="$any(feedbackService.feedbackForm)?.controls['FeedbackCategory']" tabIndex="0">
    @for (feedbackCategory of (dropdownValuesService.dropdownValues$ | async)?.FeedbackCategories; track $index) {
      <mat-option [value]="feedbackCategory.FeedbackCategoryId">
        {{ feedbackCategory.FeedbackCategoryDescription[translationService.locale] }}
      </mat-option>
    }
  </mat-select>
</mat-form-field>
