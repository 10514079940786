import { Component, EventEmitter, HostBinding, Input, OnDestroy } from '@angular/core';
import { FeedbackService } from '../services/feedback.service';
import { TranslationService } from '../services/translation.service';
import { tap } from 'rxjs/operators';
import { UserService } from '../services/user.service';

@Component({
  selector: 'portal-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
  exportAs: 'portal-feedback',
})
export class FeedbackComponent implements OnDestroy {
  private _destroyEmitter: EventEmitter<void> = new EventEmitter<void>();
  @Input() infoText: string;
  @Input() infoSubtext: string;
  @Input() showAlternativeContactOptions = false;
  @HostBinding('class.grey-form-field-background') public greyFormFieldBackground = true;

  constructor(
    public translationService: TranslationService,
    public feedbackService: FeedbackService,
    private _userService: UserService
  ) {
    this.feedbackService.resetFeedback(this._destroyEmitter);

    this._userService.currentUser$
      .pipe(
        tap((currentUser) => {
          if (currentUser?.UserEMailAddress) {
            this.feedbackService.feedbackForm.get('FeedbackEMailAddress').disable();
          } else {
            this.feedbackService.feedbackForm.get('FeedbackEMailAddress').enable();
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroyEmitter.next();
  }
}
