import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { BaseDialogModule } from '../base-dialog/base-dialog.module';
import { MarkerSketchModule } from '../marker-sketch/marker-sketch.module';
import { PayPalButtonDirective } from './paypal-button.directive';
import { PayPalPaymentCancelledComponent } from './paypal-payment-cancelled/paypal-payment-cancelled.component';

@NgModule({
  declarations: [PayPalButtonDirective, PayPalPaymentCancelledComponent],
  imports: [CommonModule, BaseDialogModule, MarkerSketchModule, MatButtonModule, MatIconModule],
  exports: [PayPalButtonDirective],
})
export class PayPalModule {}
