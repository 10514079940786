<portal-base-dialog
  [portalIcon]="'enthus-credit_card_off'"
  [header]="translationService.translations.headers.PayPal.PaymentCancelled.toString()"
  [confirmButtonDescription]="translationService.translations.action.Close.toString()"
  (primaryAction)="baseDialogComponent.dismiss()"
  [hideDismissButton]="true"
  [contentPadding]="false"
  #baseDialogComponent
>
  <ng-container class="base-dialog-content">
    <portal-marker-sketch [infoMessage]="translationService.translations.error.PayPal.TransactionCancelled.toString()" [svgIcon]="'enthus-credit_card_off'">
      <pre>{{ translationService.translations.information.PayPal.TransactionCancelled }}</pre>
    </portal-marker-sketch>
  </ng-container>
  <ng-container class="additional-actions-left">
    <button mat-stroked-button (click)="openFeedbackDialog(); baseDialogComponent.dismiss()">
      <mat-icon [svgIcon]="'enthus-feedback'"></mat-icon>&nbsp;{{ translationService.translations.action.PayPal.SendFeedback }}
    </button>

    <button mat-stroked-button (click)="openRequestSupportDialog(); baseDialogComponent.dismiss()">
      <mat-icon [svgIcon]="'enthus-contact_support'"></mat-icon>&nbsp;{{ translationService.translations.action.PayPal.RequestSupport }}
    </button>
  </ng-container>
</portal-base-dialog>
