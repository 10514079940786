import { Component } from '@angular/core';

import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslationService } from '../services/translation.service';
import { SettingsService } from '../services/settings.service';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'portal-dev-navigation',
  standalone: true,
  imports: [MatMenuModule, RouterLink, MatIconModule, MatToolbarModule, MatSlideToggleModule, MatDividerModule, MatButtonModule],
  templateUrl: './dev-navigation.component.html',
  styleUrls: ['./dev-navigation.component.scss'],
})
export class DevNavigationComponent {
  public isCustomToolbarHidden = false;
  public readonly location = location;

  constructor(
    public translationService: TranslationService,
    public settingsService: SettingsService
  ) {}
}
