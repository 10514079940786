import { Component, Input } from '@angular/core';
import { FeedbackService } from '../../services/feedback.service';
import { TranslationService } from '../../services/translation.service';

@Component({
  selector: 'portal-feedback-textbox',
  templateUrl: './feedback-textbox.component.html',
  styleUrls: ['./feedback-textbox.component.scss'],
})
export class FeedbackTextboxComponent {
  @Input() maxLength = 1000;
  @Input() rows = 10;

  constructor(
    public translationService: TranslationService,
    public feedbackService: FeedbackService
  ) {}
}
