import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BaseDialogModule } from '../base-dialog/base-dialog.module';
import { BreadcrumbsModule } from '../breadcrumbs/breadcrumbs.module';
import { ContextualSearchModule } from '../contextual-search/contextual-search.module';
import { DocumentPositionDescriptionModule } from '../document-position/document-position-description/document-position-description.module';
import { DocumentPositionMediaModule } from '../document-position/document-position-media/document-position-media.module';
import { DocumentPositionModule } from '../document-position/document-position.module';
import { OciModule } from '../oci/oci.module';
import { MarkerSketchModule } from '../marker-sketch/marker-sketch.module';
import { NumberLinkModule } from '../number-link/number-link.module';
import { PayPalModule } from '../paypal/paypal.module';
import { QuantityKeyModule } from '../quantity-key/quantity-key.module';
import { CartPositionComponent } from './cart-position/cart-position.component';
import { CartPriceDetailsModule } from './cart-price-details/cart-price-details.module';
import { CartComponent } from './cart.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { DocumentCreatedDialogComponent } from './checkout/document-created-dialog/document-created-dialog.component';
import { OciCartComponent } from './oci-cart/oci-cart.component';
import { BaseCartComponent } from './base-cart/base-cart.component';
import { MatTableModule } from '@angular/material/table';
import { CheckoutDetailsComponent } from './checkout/checkout-details/checkout-details.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { PhoneOrEmailModule } from '../phone-or-email/phone-or-email.module';
import { DevNavigationComponent } from '../dev-navigation/dev-navigation.component';
import { TosAppliedComponent } from '../text-with-links/tos-applied/tos-applied.component';
import { CheckoutPartialDeliveryComponent } from './checkout/checkout-partial-delivery/checkout-partial-delivery.component';
import { CheckoutLicenseAddressComponent } from './checkout/checkout-license-address/checkout-license-address.component';
import { CheckoutCostCenterComponent } from './checkout/checkout-cost-center/checkout-cost-center.component';

@NgModule({
  declarations: [
    BaseCartComponent,
    CartComponent,
    OciCartComponent,
    CartPositionComponent,
    CheckoutComponent,
    DocumentCreatedDialogComponent,
    CheckoutDetailsComponent,
  ],
  imports: [
    CommonModule,
    MatListModule,
    MatIconModule,
    MatTooltipModule,
    MarkerSketchModule,
    MatButtonModule,
    ScrollingModule,
    DragDropModule,
    DocumentPositionModule,
    CartPriceDetailsModule,
    MatToolbarModule,
    MatBadgeModule,
    MatStepperModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    DocumentPositionMediaModule,
    DocumentPositionDescriptionModule,
    BaseDialogModule,
    MatSelectModule,
    NumberLinkModule,
    MatRadioModule,
    PayPalModule,
    ContextualSearchModule,
    OciModule,
    BreadcrumbsModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatTableModule,
    MatDatepickerModule,
    QuantityKeyModule,
    MatBottomSheetModule,
    PhoneOrEmailModule,
    DevNavigationComponent,
    TosAppliedComponent,
    CheckoutPartialDeliveryComponent,
    CheckoutLicenseAddressComponent,
    CheckoutCostCenterComponent,
  ],
  exports: [BaseCartComponent],
})
export class CartModule {}
