<div class="flex">
  <div class="flex flex-auto flex-col">
    <span class="cost-center">
      @if ((selectedCostCenter$ | async)?.Description) {
        {{ (selectedCostCenter$ | async)?.Description }}
      } @else {
        {{ translationService.translations.labels.NoCostCenter }}
      }
    </span>
    @if (
      (selectedCostCenter$ | async)?.ResponsibleContactUUID &&
      (selectedCostCenter$ | async)?.ResponsibleContactUUID !== costCenterService.noCostCenterUUID &&
      (selectedCostCenter$ | async)?.ResponsibleContactUUID !== userService.currentUser$?.value?.UserUUID
    ) {
      <span class="sub-text-color text-clip whitespace-normal text-xs">
        {{ (selectedCostCenter$ | async)?.ResponsibleContactDisplayName }} {{ translationService.translations.labels.InformCostCenterResponsibleContact }}
      </span>
    }
  </div>
  <button mat-raised-button color="accent" class="thin" (click)="openCostCenterDialog()" [disabled]="(loadingService.isLoading$ | async) === true">
    <mat-icon [svgIcon]="'enthus-edit'"></mat-icon>&nbsp;
    {{ translationService.translations.action.Change }}
  </button>
</div>
