import { Component, EventEmitter, Input, OnDestroy } from '@angular/core';

import { TranslationService } from '../../../services/translation.service';
import { PortalFormControl } from '../../../danger-zone/form-control-override';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { takeUntil, tap } from 'rxjs/operators';
import { BaseCartService } from '../../../services/base-cart.service';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'portal-checkout-partial-delivery',
  templateUrl: './checkout-partial-delivery.component.html',
  styleUrls: ['./checkout-partial-delivery.component.scss'],
  standalone: true,
  imports: [MatCheckboxModule, ReactiveFormsModule],
})
export class CheckoutPartialDeliveryComponent implements OnDestroy {
  @Input() public control: PortalFormControl;
  private _destroyEmitter: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    public translationService: TranslationService,
    public cartService: BaseCartService
  ) {}

  public usePartialDeliveryChange(checkboxChange: MatCheckboxChange): void {
    this.cartService
      .setCartUsePartialDelivery(checkboxChange.checked)
      .pipe(
        takeUntil(this._destroyEmitter),
        tap(() => this.control.markAsPristine())
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroyEmitter.emit();
  }
}
