import { AfterViewInit, Component, EventEmitter, Injector, OnDestroy } from '@angular/core';
import { Validators } from '@angular/forms';
import { BehaviorSubject, EMPTY, of } from 'rxjs';
import { delay, filter, finalize, map, skip, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AppService } from '../services/app.service';
import { BaseCartService } from '../services/base-cart.service';
import { OverlayService } from '../services/overlay.service';
import { SerialNumberService } from '../services/serial-number.service';
import { TranslationService } from '../services/translation.service';
import { UserService } from '../services/user.service';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';
import { PortalFormControl } from '../danger-zone/form-control-override';
import { PunchOutCatalogService } from '../services/punch-out-catalog.service';
import { BaseCartComponent } from './base-cart/base-cart.component';
import { LoadingService } from '../services/loading.service';
import { SerialNumbersDialogComponent } from '../serial-numbers/serial-numbers-dialog/serial-numbers-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DataLayerService } from '../services/data-layer.service';

@Component({
  selector: 'portal-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnDestroy, AfterViewInit {
  public checkoutAllowed$ = of(false);
  public viewReady$ = new BehaviorSubject(false);
  public viewIsReady$ = this.viewReady$.pipe(delay(this.authenticationService.authenticationData?.Authenticated ? 0 : 500));
  private _destroyEmitter: EventEmitter<void> = new EventEmitter<void>();
  public baseCartComponent = BaseCartComponent;

  constructor(
    public translationService: TranslationService,
    private _userService: UserService,
    private _serialNumberService: SerialNumberService,
    private _overlayService: OverlayService,
    public appService: AppService,
    private _injector: Injector,
    private _cartService: BaseCartService,
    public authenticationService: AuthenticationService,
    private _router: Router,
    private _punchOutCatalogService: PunchOutCatalogService,
    public loadingService: LoadingService,
    private _matDialog: MatDialog,
    private _dataLayerService: DataLayerService
  ) {
    this.authenticationService.isUserAuthenticated().pipe(takeUntil(this._destroyEmitter), take(1)).subscribe();

    this.checkoutAllowed$ = this._userService.currentUser$.asObservable().pipe(
      filter((user) => !!user),
      map((user) => user.UserPermissionNames.includes('AllowCheckout'))
    );

    this._cartService.cart$
      .pipe(
        filter((cart) => !!cart),
        take(1)
      )
      .subscribe((cart) => {
        for (const cartElement of cart.CartPositions) {
          BaseCartComponent.cartPositionsForm.addControl(
            (cartElement.PositionItemId || cartElement.PositionId).toString(),
            new PortalFormControl(this._destroyEmitter, cartElement.PositionItemQuantity, Validators.required)
          );
        }

        this.loadingService.isLoading$.next(false);
      });

    this.loadingService.isLoading$
      .pipe(
        takeUntil(this._destroyEmitter),
        tap((isLoading) => {
          if (isLoading) {
            BaseCartComponent.cartPositionsForm.disable({ emitEvent: false });
          } else {
            BaseCartComponent.cartPositionsForm.enable({ emitEvent: false });
          }
        })
      )
      .subscribe();

    this._cartService.getCartFromBackend().pipe(take(1)).subscribe();

    this._cartService.cart$
      .pipe(
        filter((cart) => !!cart),
        skip(1),
        take(1),
        takeUntil(this._destroyEmitter),
        tap(() => this._pushCurrentCartToDataLayer('view_cart'))
      )
      .subscribe();
  }

  public goToCheckout(): void {
    if (this._cartService.cart$.value.CartPositions.length > 0) {
      const cartPositionsWithRequiredSerialNumbers = this._cartService.cart$.value.CartPositions.filter((position) => position.PositionRequireSerialNumbers);

      if (cartPositionsWithRequiredSerialNumbers.length > 0) {
        SerialNumbersDialogComponent.openSerialNumbersDialog(cartPositionsWithRequiredSerialNumbers, false, this._matDialog, null, true)
          .pipe(
            switchMap((result) => {
              if (result === 'notAllSerialNumbersAreSaved') {
                this._overlayService.openTextOverlay(
                  this.translationService.translations.serialNumbers.NotAllSerialNumbersAddedWarning.toString(),
                  this.translationService.translations.serialNumbers.GoToCheckoutWithNotAllSerialNumbersText.toString()
                );
              } else if (result === 'savedSerialNumbers') {
                this._router.navigate(['/checkout'], { queryParams: { document: 'order' } });
              }

              return EMPTY;
            })
          )
          .subscribe();
      } else {
        this._router.navigate(['/checkout'], { queryParams: { document: 'order' } });
      }

      this._pushCurrentCartToDataLayer('begin_checkout');
    }
  }

  public goToCreateOffer(): void {
    if (this._cartService.cart$.value.CartPositions.length > 0) {
      const cartPositionsWithRequiredSerialNumbers = this._cartService.cart$.value.CartPositions.filter((position) => position.PositionRequireSerialNumbers);

      if (cartPositionsWithRequiredSerialNumbers.length > 0) {
        this._serialNumberService
          .openSerialNumbersInfoDialog(cartPositionsWithRequiredSerialNumbers)
          .pipe(
            switchMap((result) => {
              if (result === 'addSerialNumbers') {
                return SerialNumbersDialogComponent.openSerialNumbersDialog(cartPositionsWithRequiredSerialNumbers, false, this._matDialog, null, true);
              } else {
                return EMPTY;
              }
            }),
            finalize(() => {
              this._router.navigate(['/checkout'], { queryParams: { document: 'offer' } });
            })
          )
          .subscribe();
      } else {
        this._router.navigate(['/checkout'], { queryParams: { document: 'offer' } });
      }

      this._pushCurrentCartToDataLayer('begin_offerplacement');
    }
  }

  public ngOnDestroy(): void {
    this._destroyEmitter.next();
  }

  loginToContinue(): void {
    this.authenticationService.setAccessedUrl('/cart');
    this._router.navigate(['/login']);
  }

  ngAfterViewInit(): void {
    this.viewReady$.next(true);
  }

  private _pushCurrentCartToDataLayer(eventName: string): void {
    this._dataLayerService.pushDataLayer(
      eventName,
      {
        value: this._cartService.cartPrice$.value.totalWithoutVat.toFixed(2),
        currency: this._cartService.currencyISOSymbol,
      },
      'cart',
      this._cartService.cart$.value.CartPositions
    );
  }
}
