<portal-base-dialog
  [icon]="matDialogData.errorIconIsPortal ? '' : matDialogData.errorIcon"
  [portalIcon]="matDialogData.errorIconIsPortal ? matDialogData.errorIcon : ''"
  [header]="matDialogData.errorHeader"
  [confirmButtonDescription]="translationService.translations.action.Close.toString()"
  (primaryAction)="dialogRef.close()"
  [hideDismissButton]="true"
  [contentPadding]="false"
  [preventClose]="true"
>
  <ng-container class="base-dialog-content">
    <portal-marker-sketch
      [infoMessage]="matDialogData.errorInfoMessage"
      [icon]="matDialogData.errorIconIsPortal ? '' : matDialogData.errorIcon"
      [svgIcon]="matDialogData.errorIconIsPortal ? matDialogData.errorIcon : ''"
      [iconColor]="'warn'"
    >
      <pre>{{ translationService.translations.information.ErrorDialog.SorryForError }}</pre>
      <pre>{{ translationService.translations.information.ErrorDialog.TryAgainOrRequestSupport }}</pre>
      <pre>{{ translationService.translations.information.ErrorDialog.FeedbackIsImportant }}</pre>
    </portal-marker-sketch>
  </ng-container>
  <ng-container class="additional-actions-left">
    <button mat-raised-button color="accent" (click)="openFeedbackDialog(); dialogRef.close()">
      <mat-icon [svgIcon]="'enthus-feedback'"></mat-icon>&nbsp;{{ translationService.translations.action.SendFeedback }}
    </button>

    <button mat-raised-button color="accent" (click)="openRequestSupportDialog(); dialogRef.close()">
      <mat-icon [svgIcon]="'enthus-contact_support'"></mat-icon>&nbsp;{{ translationService.translations.action.RequestSupport }}
    </button>
  </ng-container>
</portal-base-dialog>
