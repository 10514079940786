import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { DocumentCreatedDialogComponent } from '../cart/checkout/document-created-dialog/document-created-dialog.component';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { IOfferCartResponse, IOrderCartResponse } from '../interfaces/cart.interface';
import { BaseCartService } from './base-cart.service';
import { DialogService } from './dialog.service';
import { ErrorHandlingService } from './error-handling.service';
import { TranslationService } from './translation.service';
import { PortalFormGroup } from '../danger-zone/form-group-override';
import { DataLayerService } from './data-layer.service';

@Injectable({
  providedIn: 'root',
})
export class CheckoutService {
  private _errorDialog: MatDialogRef<ErrorDialogComponent>;

  constructor(
    private _errorHandlingService: ErrorHandlingService,
    private _cartService: BaseCartService,
    private _router: Router,
    private _matDialog: MatDialog,
    private _dialogService: DialogService,
    private _translationService: TranslationService,
    private _dataLayerService: DataLayerService
  ) {}

  public finishOrder(): Observable<IOrderCartResponse> {
    return this._errorHandlingService.handleErrorWithBannerAndRetry<IOrderCartResponse>({
      endpoint: this.orderCart().pipe(
        take(1),
        tap(() => {
          if (this._errorDialog) this._errorDialog.close();
        })
      ),
      propertyModification: null,
      bannerTitle: this._translationService.translations.error.FinishOrderFailed.toString(),
      bannerSubtitle: null,
      sideSheetComponent: null,
      onDismiss: () => {
        if (this._errorDialog) this._errorDialog.close();

        this._errorDialog = this._dialogService.openDialog(ErrorDialogComponent, {
          hasBackdrop: true,
          data: {
            errorIcon: 'enthus-orders',
            errorIconIsPortal: true,
            errorInfoMessage: this._translationService.translations.error.FinishOrderError.toString(),
            errorHeader: this._translationService.translations.headers.FinishOrderError.toString(),
          },
        });
      },
      rethrowError: true,
      cart: JSON.parse(JSON.stringify(this._cartService.cart$.value)),
    });
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public orderCart(paypalOrderID?: string, mock_application_codes?: { mock_application_codes: string }): Observable<IOrderCartResponse> {
    return this._cartService.orderCart(paypalOrderID, mock_application_codes).pipe(
      switchMap((response: IOrderCartResponse) => {
        this._dataLayerService.pushDataLayer(
          'purchase',
          {
            value: this._cartService.cartPrice$.value.totalWithoutVat.toFixed(2),
            shipping: parseFloat(this._cartService.shippingCharges.toFixed(2)),
            currency: this._cartService.currencyISOSymbol,
          },
          'cart',
          this._cartService.cart$.value.CartPositions
        );

        this._cartService.skipRipple = true;
        this._cartService.skipSnackBar = true;
        return this._cartService.clearCart(true).pipe(map(() => response));
      }),
      tap((response: IOrderCartResponse) => {
        this._router.navigate(['/cart']).then(() => {
          this._matDialog.open(DocumentCreatedDialogComponent, {
            data: {
              documentNo: response.OrderNo,
              documentType: 'Order',
            },
            hasBackdrop: true,
          });
        });
      }),
      catchError((error) => throwError(() => error))
    );
  }

  public requestOffer(): Observable<IOfferCartResponse> {
    return this._errorHandlingService.handleErrorWithBannerAndRetry<IOfferCartResponse>({
      endpoint: this._cartService.requestOffer().pipe(
        switchMap((response: IOfferCartResponse) => {
          this._cartService.skipRipple = true;
          this._cartService.skipSnackBar = true;
          this._dataLayerService.pushDataLayer(
            'place_offer',
            {
              value: this._cartService.cartPrice$.value.totalWithoutVat.toFixed(2),
              shipping: parseFloat(this._cartService.shippingCharges.toFixed(2)),
              currency: this._cartService.currencyISOSymbol,
            },
            'cart',
            this._cartService.cart$.value.CartPositions
          );
          return this._cartService.clearCart(true).pipe(map(() => response));
        }),
        tap((response: IOfferCartResponse) => {
          this._router.navigate(['/cart']).then(() => {
            this._matDialog.open(DocumentCreatedDialogComponent, {
              data: {
                documentNo: response.OfferNo,
                documentType: 'Offer',
              },
              hasBackdrop: true,
            });
          });

          if (this._errorDialog) {
            this._errorDialog.close();
          }
        }),
        catchError((error) => {
          if (this._errorDialog) {
            this._errorDialog.close();
          }

          this._errorDialog = this._dialogService.openDialog(ErrorDialogComponent, {
            hasBackdrop: true,
            data: {
              errorIcon: 'enthus-offers',
              errorIconIsPortal: true,
              errorInfoMessage: this._translationService.translations.error.RequestOfferError.toString(),
              errorHeader: this._translationService.translations.headers.RequestOfferError.toString(),
            },
          });
          return throwError(() => error);
        })
      ),
      propertyModification: null,
      bannerTitle: this._translationService.translations.error.RequestOfferFailed.toString(),
    });
  }

  public restoreFormState(shouldDisable: boolean, form: PortalFormGroup, initiallyDisabledControls: string[]): void {
    if (shouldDisable) {
      Object.keys(form.controls).forEach((control) => {
        if (form.controls[control].disabled) {
          initiallyDisabledControls.push(control);
        }
      });

      form.disable({ emitEvent: false });
    } else {
      Object.keys(form.controls).forEach((control) => {
        if (!initiallyDisabledControls.includes(control)) {
          form.get(control).enable({ emitEvent: false });
        }
      });

      initiallyDisabledControls.length = 0;
    }
  }
}
