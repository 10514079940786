import { Component } from '@angular/core';
import { DropdownValuesService } from '../../services/dropdown-values.service';
import { FeedbackService } from '../../services/feedback.service';
import { TranslationService } from '../../services/translation.service';

@Component({
  selector: 'portal-feedback-category',
  templateUrl: './feedback-category.component.html',
  styleUrls: ['./feedback-category.component.scss'],
})
export class FeedbackCategoryComponent {
  constructor(
    public translationService: TranslationService,
    public feedbackService: FeedbackService,
    public dropdownValuesService: DropdownValuesService
  ) {}
}
