import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { TranslationService } from '../../../services/translation.service';
import { BehaviorSubject } from 'rxjs';
import { CostCenterService, ICostCenter } from '../../../services/cost-center.service';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { LoadingService } from '../../../services/loading.service';
import { AsyncPipe } from '@angular/common';
import { filter, finalize, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { UserService } from '../../../services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { SelectCostCenterDialogComponent } from '../../../cost-centers/select-cost-center-dialog/select-cost-center-dialog.component';
import { BaseCartService } from '../../../services/base-cart.service';

@Component({
  selector: 'portal-checkout-cost-center',
  standalone: true,
  imports: [ReactiveFormsModule, MatButton, MatIcon, AsyncPipe],
  templateUrl: './checkout-cost-center.component.html',
  styleUrl: './checkout-cost-center.component.scss',
})
export class CheckoutCostCenterComponent implements OnInit, OnDestroy {
  private _destroyEmitter: EventEmitter<void> = new EventEmitter<void>();
  public selectedCostCenter$: BehaviorSubject<ICostCenter> = new BehaviorSubject<ICostCenter>(null);

  constructor(
    public translationService: TranslationService,
    public costCenterService: CostCenterService,
    public loadingService: LoadingService,
    public userService: UserService,
    private _matDialog: MatDialog,
    public cartService: BaseCartService
  ) {}

  ngOnInit(): void {
    const selectedCostCenter = this.cartService.cart$.value.CostCenterUUID;

    this.costCenterService
      .getCostCenters()
      .pipe(
        takeUntil(this._destroyEmitter),
        map(function (costCenters) {
          return costCenters.find((costCenter) => costCenter.UUID === selectedCostCenter);
        }),
        tap((foundCostCenter: ICostCenter) => this.selectedCostCenter$.next(foundCostCenter))
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroyEmitter.next();
  }

  openCostCenterDialog(): void {
    this.loadingService.isLoading$.next(true);

    const showCostCenterDialogRef = this._matDialog.open(SelectCostCenterDialogComponent, {
      data: { costCenterUUID: this.cartService.cart$.value.CostCenterUUID },
      hasBackdrop: true,
    });

    showCostCenterDialogRef
      .afterClosed()
      .pipe(
        take(1),
        takeUntil(this._destroyEmitter),
        filter((selectedCostCenter) => selectedCostCenter),
        switchMap((selectedCostCenter: ICostCenter) => {
          return this.cartService.setCostCenter(selectedCostCenter.UUID).pipe(
            finalize(() => {
              this.selectedCostCenter$.next(selectedCostCenter);
              this.loadingService.isLoading$.next(false);
            })
          );
        })
      )
      .subscribe();
  }
}
