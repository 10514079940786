import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FeedbackService } from '../services/feedback.service';
import { TranslationService } from '../services/translation.service';
import { FeedbackDialogComponent } from '../feedback/feedback-dialog/feedback-dialog.component';

export interface IErrorDialogData {
  errorHeader: string;
  errorInfoMessage: string;
  errorIcon: string;
  errorIconIsPortal: boolean;
  feedbackCategoryId: number;
  feedbackDialogHeader: string;
}

@Component({
  selector: 'portal-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss'],
})
export class ErrorDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public matDialogData: IErrorDialogData,
    public dialogRef: MatDialogRef<ErrorDialogComponent>,
    public translationService: TranslationService,
    private _feedbackService: FeedbackService,
    private _matDialog: MatDialog
  ) {}

  public openFeedbackDialog(): void {
    FeedbackDialogComponent.openFeedbackDialog(this._feedbackService, this._matDialog, {
      dialogHeaderText: this.matDialogData.feedbackDialogHeader || this.translationService.translations.headers.Feedback.toString(),
      feedbackInfoText: null,
      feedbackInfoSubtext: null,
      feedbackCategoryId: this.matDialogData.feedbackCategoryId || 3,
    });
  }

  public openRequestSupportDialog(): void {
    FeedbackDialogComponent.openFeedbackDialog(this._feedbackService, this._matDialog, {
      headerIcon: 'enthus-contact_support',
      dialogHeaderText: this.translationService.translations.headers.RequestSupport.toString(),
      feedbackInfoText: null,
      feedbackInfoSubtext: null,
      feedbackCategoryId: this.matDialogData.feedbackCategoryId || 3,
      showAlternativeContactOptions: true,
    });
  }
}
