import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, concatMap, debounceTime, filter, finalize, takeUntil, tap } from 'rxjs/operators';
import { PortalFormControl } from '../../../danger-zone/form-control-override';
import { BaseCartService } from '../../../services/base-cart.service';
import { TranslationService } from '../../../services/translation.service';
import moment from 'moment/moment';
import { ActivatedRoute } from '@angular/router';
import { AppService } from '../../../services/app.service';
import { Moment } from 'moment';
import { PortalFormGroup } from '../../../danger-zone/form-group-override';
import { ImmediateMatcher } from '../../../services/user-profile.service';
import { LoadingService } from '../../../services/loading.service';

export class ShowSaveErrorInstant implements ErrorStateMatcher {
  public isErrorState(control: PortalFormControl | null): boolean {
    return !!control?.hasError('saveFailed');
  }
}

@Component({
  selector: 'portal-checkout-details',
  templateUrl: './checkout-details.component.html',
  styleUrls: ['./checkout-details.component.scss'],
})
export class CheckoutDetailsComponent implements OnInit, OnDestroy {
  public descriptionValueSaved = false;
  public referenceValueSaved = false;
  public remarkValueSaved = false;
  public remarkControlLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public descriptionControlLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public referenceNoControlLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showErrorInstantErrorStateMatcher = new ShowSaveErrorInstant();
  private _destroyEmitter: EventEmitter<void> = new EventEmitter<void>();
  public createAsOrder = false;
  public dateTomorrow: string = moment().add(1, 'days').format();
  public immediateMatcher = new ImmediateMatcher();
  public deliveryDateDropDownValues = [
    {
      DeliveryDateTypeID: 1,
      DeliveryDateSpecification: { en: 'until', de: 'Bis zum' },
    },
    {
      DeliveryDateTypeID: 2,
      DeliveryDateSpecification: { en: 'on the', de: 'Am' },
    },
    {
      DeliveryDateTypeID: 3,
      DeliveryDateSpecification: { en: 'from the', de: 'Ab dem' },
    },
  ];
  @Input() public checkoutDetailsForm: PortalFormGroup;

  constructor(
    public translationService: TranslationService,
    public cartService: BaseCartService,
    private _cd: ChangeDetectorRef,
    private _activatedRoute: ActivatedRoute,
    public appService: AppService,
    public loadingService: LoadingService
  ) {}

  private _listenForDescriptionControlValuesChanges(): void {
    this.checkoutDetailsForm
      .get('description')
      .valueChanges.pipe(
        takeUntil(this._destroyEmitter),
        debounceTime(500),
        filter((description) => description !== this.cartService.cart$.value.CartDescription),
        tap(() => this.descriptionControlLoading$.next(true)),
        concatMap((description) => {
          this.checkoutDetailsForm.get('description').markAsTouched();

          return this.cartService.setCartDescription(description).pipe(
            finalize(() => {
              this.descriptionControlLoading$.next(false);
              this.descriptionValueSaved = true;
            }),
            catchError(() => {
              this.checkoutDetailsForm.get('description').setErrors({ saveFailed: true });
              this._cd.markForCheck();

              return of(null);
            })
          );
        })
      )
      .subscribe(() => {
        if (!this.checkoutDetailsForm.get('description').invalid) {
          this.checkoutDetailsForm.get('description').markAsPristine();
        }
      });
  }

  private _listenForReferenceNoControlValuesChanges(): void {
    this.checkoutDetailsForm
      .get('referenceNo')
      .valueChanges.pipe(
        takeUntil(this._destroyEmitter),
        debounceTime(500),
        filter((referenceNo) => referenceNo !== this.cartService.cart$.value.CartReference),
        tap(() => this.referenceNoControlLoading$.next(true)),
        concatMap((referenceNo) => {
          this.checkoutDetailsForm.get('referenceNo').markAsTouched();

          return this.cartService.setCartReference(referenceNo).pipe(
            finalize(() => {
              this.referenceNoControlLoading$.next(false);
              this.referenceValueSaved = true;
            }),
            catchError(() => {
              this.checkoutDetailsForm.get('referenceNo').setErrors({ saveFailed: true });
              this._cd.markForCheck();

              return of(null);
            })
          );
        })
      )
      .subscribe(() => {
        if (!this.checkoutDetailsForm.get('referenceNo').invalid) {
          this.checkoutDetailsForm.get('referenceNo').markAsPristine();
        }
      });
  }

  private _listenForRemarkControlValuesChanges(): void {
    this.checkoutDetailsForm
      .get('remark')
      .valueChanges.pipe(
        takeUntil(this._destroyEmitter),
        debounceTime(500),
        filter((remark) => remark !== this.cartService.cart$.value.CartRemark),
        tap(() => this.remarkControlLoading$.next(true)),
        concatMap((remark) => {
          this.checkoutDetailsForm.get('remark').markAsTouched();

          return this.cartService.setCartRemark(remark).pipe(
            finalize(() => {
              this.remarkControlLoading$.next(false);
              this.remarkValueSaved = true;
            }),
            catchError(() => {
              this.checkoutDetailsForm.get('remark').setErrors({ saveFailed: true });
              this._cd.markForCheck();

              return of(null);
            })
          );
        })
      )
      .subscribe(() => {
        if (!this.checkoutDetailsForm.get('remark').invalid) {
          this.checkoutDetailsForm.get('remark').markAsPristine();
        }
      });
  }

  private _listenForDeliveryDateTypeControlValuesChanges(): void {
    this.checkoutDetailsForm
      .get('deliveryDateGroup')
      .get('deliveryDateTypeControl')
      .valueChanges.pipe(
        takeUntil(this._destroyEmitter),
        debounceTime(500),
        filter((specification) => specification !== this.cartService.cart$.value.CartDeliveryDateTypeID),
        concatMap((specification: number) => {
          this.checkoutDetailsForm.get('deliveryDateGroup').get('deliveryDateTypeControl').markAsTouched();

          return this.cartService.setCartDeliveryDateTypeID(specification).pipe(
            catchError(() => {
              this.checkoutDetailsForm.get('deliveryDateGroup').get('deliveryDateTypeControl').setErrors({ saveFailed: true });
              this._cd.markForCheck();

              return of(null);
            })
          );
        })
      )
      .subscribe(() => {
        if (!this.checkoutDetailsForm.get('deliveryDateGroup').get('deliveryDateTypeControl').invalid) {
          this.checkoutDetailsForm.get('deliveryDateGroup').get('deliveryDateTypeControl').markAsPristine();
        }
      });
  }

  private _listenForDeliveryDateControlValuesChanges(): void {
    this.checkoutDetailsForm
      .get('deliveryDateGroup')
      .get('deliveryDateControl')
      .valueChanges.pipe(
        takeUntil(this._destroyEmitter),
        debounceTime(500),
        filter((date) => {
          const cartDeliveryDate = this.cartService.cart$.value.CartDeliveryDate;

          return (date && (!cartDeliveryDate || moment(date).utc().valueOf() != moment(cartDeliveryDate).utc().valueOf())) || (!date && !!cartDeliveryDate);
        }),
        concatMap((date: Moment) => {
          this.checkoutDetailsForm.get('deliveryDateGroup').get('deliveryDateControl').markAsTouched();

          return this.cartService.setCartDeliveryDate(date).pipe(
            catchError(() => {
              this.checkoutDetailsForm.get('deliveryDateGroup').get('deliveryDateControl').setErrors({ saveFailed: true });
              this._cd.markForCheck();

              return of(null);
            })
          );
        })
      )
      .subscribe(() => {
        if (!this.checkoutDetailsForm.get('deliveryDateGroup').get('deliveryDateControl').invalid) {
          this.checkoutDetailsForm.get('deliveryDateGroup').get('deliveryDateControl').markAsPristine();
        }
      });
  }

  public ngOnDestroy(): void {
    this._destroyEmitter.next();
  }

  ngOnInit(): void {
    if (!this.checkoutDetailsForm) throw new Error('The checkoutDetailsForm must be provided to the component input');

    if (!this.loadingService.isLoading$) {
      throw new Error('loading$ must be provided to the component input');
    } else {
      this.loadingService.isLoading$
        .pipe(
          takeUntil(this._destroyEmitter),
          tap((isLoading) => (isLoading ? this.checkoutDetailsForm.disable() : this.checkoutDetailsForm.enable()))
        )
        .subscribe();
    }

    if (this._activatedRoute.snapshot.queryParams && this._activatedRoute.snapshot.queryParams.document === 'order') {
      this.createAsOrder = true;
    }

    this._listenForDescriptionControlValuesChanges();
    this._listenForReferenceNoControlValuesChanges();
    this._listenForRemarkControlValuesChanges();
    this._listenForDeliveryDateTypeControlValuesChanges();
    this._listenForDeliveryDateControlValuesChanges();
  }
}
