@if (infoText) {
  <p class="feedback-info-text">{{ infoText }}</p>
}
@if (infoSubtext) {
  <p class="feedback-info-subtext">{{ infoSubtext }}</p>
}
<portal-feedback-category></portal-feedback-category>
<portal-feedback-textbox></portal-feedback-textbox>
@if (feedbackService.feedbackForm.controls['FeedbackEMailAddress'].value || feedbackService.feedbackForm.controls['FeedbackEMailAddress'].enabled) {
  <mat-form-field color="accent">
    <mat-label>{{ translationService.translations.common.EMailAddress }}</mat-label>
    <input [formControl]="$any(feedbackService).feedbackForm.controls['FeedbackEMailAddress']" matInput />
  </mat-form-field>
}
<ng-content></ng-content>
@if (showAlternativeContactOptions) {
  <mat-divider></mat-divider>
  <span>{{ translationService.translations.information.AlternativeContactOptions }}:</span>
  <portal-phone-or-email [subject]="feedbackService.feedbackSubject$ | async"></portal-phone-or-email>
}
