import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BreadcrumbService } from '../services/breadcrumb.service';
import { TranslationService } from '../services/translation.service';
import { MasterDetailRouteReuseStrategy } from '../master-detail-route-reuse-strategy';

@Component({
  selector: 'portal-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent {
  public location = window.location;

  constructor(
    public breadcrumbService: BreadcrumbService,
    public translationService: TranslationService,
    private _router: Router
  ) {}

  public navigateToBreadcrumb(link: string): void {
    MasterDetailRouteReuseStrategy.skipRouteAttach = true;
    this._router.navigate([link]).then(() => (MasterDetailRouteReuseStrategy.skipRouteAttach = false));
  }
}
