export enum PayPalCurrencyCodes {
  EUR = 'EUR',
}

export enum PayPalINTENT {
  CAPTURE = 'CAPTURE',
  AUTHORIZE = 'AUTHORIZE',
}

export enum PayPalLandingPage {
  LOGIN = 'LOGIN',
  BILLING = 'BILLING',
  NO_PREFERENCE = 'NO_PREFERENCE',
}

export enum PayPalShippingPreference {
  GET_FROM_FILE = 'GET_FROM_FILE', // Use the customer-provided shipping address on the PayPal site.
  NO_SHIPPING = 'NO_SHIPPING', // Redact the shipping address from the PayPal site. Recommended for digital goods.
  SET_PROVIDED_ADDRESS = 'SET_PROVIDED_ADDRESS', // Use the merchant-provided address. The customer cannot change this address on the PayPal site.
}

export enum PayPalUserAction {
  CONTINUE = 'CONTINUE',
  PAY_NOW = 'PAY_NOW',
}

export enum PayPalPayeePreferred {
  UNRESTRICTED = 'UNRESTRICTED', // Accepts any type of payment from the customer.
  IMMEDIATE_PAYMENT_REQUIRED = 'IMMEDIATE_PAYMENT_REQUIRED', // Accepts only immediate payment from the customer. For example, credit card, PayPal balance, or instant ACH. Ensures that at the time of capture, the payment does not have the `pending` status.
}

export enum PayPalPaymentInitiator {
  CUSTOMER = 'CUSTOMER', // Payment is initiated with the active engagement of the customer. e.g. a customer checking out on a merchant website.
  MERCHANT = 'MERCHANT', // Payment is initiated by merchant on behalf of the customer without the active engagement of customer. e.g. a merchant charging the monthly payment of a subscription to the customer.
}

export enum PayPalPaymentType {
  ONE_TIME = 'ONE_TIME', // One Time payment such as online purchase or donation. (e.g. Checkout with one-click).
  RECURRING = 'RECURRING', // Payment which is part of a series of payments with fixed or variable amounts, following a fixed time interval. (e.g. Subscription payments).
  UNSCHEDULED = 'UNSCHEDULED', // Payment which is part of a series of payments that occur on a non-fixed schedule and/or have variable amounts. (e.g. Account Topup payments).
}

export enum PayPalNetwork {
  VISA,
  MASTERCARD,
  DISCOVER,
  AMEX,
  SOLO,
  JCB,
  STAR,
  DELTA,
  SWITCH,
  MAESTRO,
  CB_NATIONALE,
  CONFIGOGA,
  CONFIDIS,
  ELECTRON,
  CETELEM,
  CHINA_UNION_PAY,
}

export enum PayPalENV {
  LOCAL = 'local',
  STAGE = 'stage',
  SANDBOX = 'sandbox',
  PRODUCTION = 'production',
  TEST = 'test',
}
