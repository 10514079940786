<div
  class="checkout-wrapper"
  [class.mobile]="(appService.isMobile$ | async) === true"
  [class.tablet-portrait]="(appService.isTablet$ | async) === true && (appService.isPortrait$ | async) === true"
  #checkoutWrapper
>
  <div class="left-column grey-form-field-background" #leftColumn>
    <mat-list>
      <mat-list-item>
        <mat-icon [svgIcon]="'enthus-receipt'" matListItemAvatar></mat-icon>
        <div matListItemTitle>
          <span>{{ translationService.translations.labels.InvoiceAddress }}</span>
        </div>
        <div matListItemLine>
          <div class="change-address-wrapper" [class.no-address-selected]="(selectedInvoiceAddress$ | async) === undefined">
            @if ((selectedInvoiceAddress$ | async) === undefined) {
              <div class="no-selected-address-wrapper">
                <div class="no-selected-address-container edit-is-forbidden">
                  <mat-icon [svgIcon]="'enthus-warning'"></mat-icon>&nbsp;
                  <p>{{ translationService.translations.common.NoSelectedAddress }}</p>
                </div>
              </div>
            } @else {
              <p>{{ (selectedInvoiceAddress$ | async)?.AddressName1 }}</p>
            }
            <!--        Change invoice address button -->
            @if ((userService.currentUser$ | async)?.UserPermissionNames.includes('ViewAddresses')) {
              <button
                mat-raised-button
                color="accent"
                class="thin"
                (click)="showOrChangeAddressDialog(selectedInvoiceAddress$, 'invoice')"
                [disabled]="(loadingService.isLoading$ | async) === true || checkoutForm.get('invoiceAddressAdditionalInformation').disabled"
                [attr.data-test-selector]="'changeInvoiceAddressButton'"
              >
                <mat-icon [svgIcon]="'enthus-edit'"></mat-icon>&nbsp;
                {{ translationService.translations.action.Change }}
              </button>
            }
          </div>
          @if (selectedInvoiceAddress$ | async) {
            <mat-form-field class="address-additional-information">
              <mat-label>{{ translationService.translations.fields.AddressAdditionalInformation }}</mat-label>
              <!--          invoice address additional information input -->
              <input
                matInput
                [formControl]="$any(checkoutForm.get('invoiceAddressAdditionalInformation'))"
                [attr.data-test-selector]="'invoiceAddressAdditionalInformationInput'"
                [disabled]="(loadingService.isLoading$ | async) === true || checkoutForm.get('invoiceAddressAdditionalInformation').disabled"
                [maxLength]="50"
              />
              @if (
                checkoutForm.get('invoiceAddressAdditionalInformation').touched &&
                invoiceAddressAdditionalInformationSaved &&
                ((cartService.cart$ | async).CartInvoiceAddressAdditionalInformation || '') === checkoutForm.get('invoiceAddressAdditionalInformation').value &&
                ((additionalInformationLoading$ | async) === false || !checkoutForm.get('invoiceAddressAdditionalInformation').dirty)
              ) {
                <mat-icon matIconSuffix [svgIcon]="'enthus-done'"> </mat-icon>
              }
              @if (
                (additionalInformationLoading$ | async) === true &&
                checkoutForm.get('invoiceAddressAdditionalInformation').dirty &&
                checkoutForm.get('invoiceAddressAdditionalInformation').valid
              ) {
                <mat-progress-spinner mode="indeterminate" color="accent" diameter="20" matIconSuffix></mat-progress-spinner>
              }
            </mat-form-field>
            <p class="mat-card-subtitle">{{ (selectedInvoiceAddress$ | async)?.AddressStreet }}</p>
            <p class="mat-card-subtitle">{{ (selectedInvoiceAddress$ | async)?.AddressZipCodeNo }}, {{ (selectedInvoiceAddress$ | async)?.AddressTown }}</p>
            <p class="mat-card-subtitle">{{ (selectedInvoiceAddress$ | async)?.AddressCountryDescription }}</p>
          }
        </div>
        <mat-divider></mat-divider>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item>
        <mat-icon [svgIcon]="'enthus-local_shipping'" matListItemAvatar></mat-icon>
        <div matListItemTitle>
          <span>{{ translationService.translations.labels.DeliveryAddress }}</span>
        </div>
        <div matListItemLine>
          <!--      use invoice address as delivery address checkbox -->
          <mat-checkbox
            color="primary"
            [attr.data-test-selector]="'useInvoiceAddressAsDeliveryAddressCheckbox'"
            (change)="useInvoiceAddressAsDeliveryAddressChange($event)"
            [formControl]="$any(checkoutForm.get('useInvoiceAddressAsDeliveryAddress'))"
            [disabled]="
              (loadingService.isLoading$ | async) === true ||
              (selectedInvoiceAddress$ | async) === undefined ||
              checkoutForm.get('useInvoiceAddressAsDeliveryAddress').disabled
            "
          >
            {{ translationService.translations.fields.UserInvoiceAddressAsDeliveryAddress }}
          </mat-checkbox>
          <div class="change-address-wrapper" [class.no-address-selected]="(selectedDeliveryAddress$ | async) === undefined">
            @if ((selectedDeliveryAddress$ | async) === undefined) {
              <div class="no-selected-address-wrapper">
                <div class="no-selected-address-container edit-is-forbidden">
                  <mat-icon [svgIcon]="'enthus-warning'"></mat-icon>&nbsp;
                  <p>{{ translationService.translations.common.NoSelectedAddress }}</p>
                </div>
              </div>
            } @else {
              <p>{{ (selectedDeliveryAddress$ | async)?.AddressName1 }}</p>
            }
            <!--        Change delivery address button -->
            @if ((userService.currentUser$ | async)?.UserPermissionNames.includes('ViewAddresses')) {
              <button
                mat-raised-button
                color="accent"
                class="thin"
                (click)="showOrChangeAddressDialog(selectedDeliveryAddress$, 'delivery')"
                [disabled]="(loadingService.isLoading$ | async) === true || checkoutForm.get('deliveryAddressAdditionalInformation').disabled"
                [attr.data-test-selector]="'changeDeliveryAddressButton'"
              >
                <mat-icon [svgIcon]="'enthus-edit'"></mat-icon>&nbsp;
                {{ translationService.translations.action.Change }}
              </button>
            }
          </div>
          @if (selectedDeliveryAddress$ | async) {
            <mat-form-field class="address-additional-information">
              <mat-label>{{ translationService.translations.fields.AddressAdditionalInformation }}</mat-label>
              <!--          delivery address additional information input -->
              <input
                matInput
                [formControl]="$any(checkoutForm.get('deliveryAddressAdditionalInformation'))"
                [attr.data-test-selector]="'deliveryAddressAdditionalInformationInput'"
                [disabled]="(loadingService.isLoading$ | async) === true || checkoutForm.get('deliveryAddressAdditionalInformation').disabled"
                [maxLength]="50"
              />
              @if (
                checkoutForm.get('deliveryAddressAdditionalInformation').touched &&
                deliveryAddressAdditionalInformationSaved &&
                ((cartService.cart$ | async).CartDeliveryAddressAdditionalInformation || '') ===
                  checkoutForm.get('deliveryAddressAdditionalInformation').value &&
                ((additionalInformationLoading$ | async) === false || !checkoutForm.get('deliveryAddressAdditionalInformation').dirty)
              ) {
                <mat-icon matIconSuffix [svgIcon]="'enthus-done'"> </mat-icon>
              }
              @if (
                (additionalInformationLoading$ | async) === true &&
                checkoutForm.get('deliveryAddressAdditionalInformation').dirty &&
                checkoutForm.get('deliveryAddressAdditionalInformation').valid
              ) {
                <mat-progress-spinner mode="indeterminate" color="accent" diameter="20" matIconSuffix></mat-progress-spinner>
              }
            </mat-form-field>
            <p class="mat-card-subtitle">{{ (selectedDeliveryAddress$ | async)?.AddressStreet }}</p>
            <p class="mat-card-subtitle">{{ (selectedDeliveryAddress$ | async)?.AddressZipCodeNo }}, {{ (selectedDeliveryAddress$ | async)?.AddressTown }}</p>
            <p class="mat-card-subtitle">{{ (selectedDeliveryAddress$ | async)?.AddressCountryDescription }}</p>
          }
        </div>
        <mat-divider></mat-divider>
      </mat-list-item>
      <mat-divider></mat-divider>
      @if ((cartService.cart$ | async).CartRequiresLicenseAddress) {
        <mat-list-item>
          <mat-icon matListItemAvatar>receipt_long</mat-icon>
          <div matListItemTitle>
            <span>{{ translationService.translations.labels.LicenseAddress }}</span>
          </div>
          <div matListItemLine>
            <portal-checkout-license-address></portal-checkout-license-address>
          </div>
        </mat-list-item>
        <mat-divider></mat-divider>
      }
      <mat-list-item>
        <mat-icon [svgIcon]="'enthus-costcenters'" matListItemAvatar></mat-icon>
        <div matListItemTitle>
          <span>{{ translationService.translations.labels.CostCenter }}</span>
        </div>
        <div matListItemLine>
          <portal-checkout-cost-center></portal-checkout-cost-center>
        </div>
      </mat-list-item>
      <mat-list-item>
        <mat-icon [svgIcon]="'enthus-message'" matListItemAvatar></mat-icon>
        <div matListItemTitle>
          <span>{{ translationService.translations.labels.Details }}</span>
        </div>
        <div matListItemLine>
          <portal-checkout-details [checkoutDetailsForm]="$any(checkoutForm).get('checkoutDetails')"></portal-checkout-details>
        </div>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item>
        <mat-icon [svgIcon]="'enthus-call_split'" matListItemAvatar></mat-icon>
        <div matListItemTitle>
          <span>{{ translationService.translations.information.PartialDelivery }}</span>
        </div>
        <div matListItemLine>
          <portal-checkout-partial-delivery [control]="$any(checkoutForm.get('usePartialDelivery'))"></portal-checkout-partial-delivery>
        </div>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item>
        <mat-icon [svgIcon]="'enthus-checklist_rtl'" matListItemAvatar></mat-icon>
        <div matListItemTitle>
          <span>{{ translationService.translations.labels.CheckItems }}</span>
        </div>
        <div class="cart-positions" matListItemLine>
          @for (cartPosition of (cartService.cart$ | async).CartPositions; track cartPosition.PositionId; let last = $last) {
            <div>
              <div class="cart-position-row">
                <div class="media-and-description-container">
                  <portal-document-position-media [position]="cartPosition" class="mobile"></portal-document-position-media>
                  <portal-document-position-description [ellipseText]="true" [position]="cartPosition"></portal-document-position-description>
                </div>
                <div [style.width.px]="showPositionRemarkColumn ? 40 : 0" class="additional-actions">
                  @if (cartPosition.PositionCustomerRemark) {
                    <button
                      mat-icon-button
                      (click)="
                        overlayService.openTextOverlay(translationService.translations.titles.InternalRemark.toString(), cartPosition.PositionCustomerRemark)
                      "
                      [matTooltip]="translationService.translations.tooltips.ShowInternalRemark.toString()"
                    >
                      <mat-icon [svgIcon]="'enthus-insert_comment'"></mat-icon>
                    </button>
                  }
                </div>
                <div class="price-and-amount-container">
                  <span class="price">{{
                    cartPosition.PositionSalesPrice * cartPosition.PositionItemQuantity
                      | currency: cartService.currencyISOSymbol : 'symbol-narrow' : '.2-2' : translationService.locale
                  }}</span>
                  <span>
                    <portal-quantity-key
                      [quantity]="cartPosition.PositionItemQuantity"
                      [symbol]="cartPosition.PositionItemQuantityKeySymbol"
                    ></portal-quantity-key>
                  </span>
                </div>
              </div>
              @if (!last) {
                <mat-divider></mat-divider>
              }
            </div>
          }
        </div>
      </mat-list-item>
    </mat-list>
  </div>
  <div
    class="right-column"
    [style.min-width.px]="(appService.isMobile$ | async) === false ? rightColumnMinWidth : undefined"
    [class.wrapped]="isRightColumnWrapped$ | async"
  >
    <div class="payment-wrapper">
      <portal-cart-price-details></portal-cart-price-details>
      <div class="payment-condition-wrapper">
        <mat-divider></mat-divider>
        @if ((selectedInvoiceAddress$ | async) && (selectedDeliveryAddress$ | async) && !createAsOffer) {
          <div class="payment-condition-container">
            <!--      Payment condition selection -->
            <mat-radio-group
              #radioGroup="matRadioGroup"
              (change)="radioGroup?.value === 'paypal' ? payPalService.showPayPalLoading$.next(true) : null"
              [disabled]="(loadingService.isLoading$ | async) === true"
            >
              <mat-radio-button [checked]="true" value="default">
                {{ translationService.translations.common.Default }} ({{
                  (cartService.cart$ | async).CustomerPaymentConditionDescription[translationService.locale] ||
                    translationService.translations.information.PerInvoice
                }})
              </mat-radio-button>
              @if ((cartService.cart$ | async).CustomerCanUsePayPal) {
                <mat-radio-button value="paypal">
                  <div class="paypal-condition-wrapper">
                    <span>PayPal</span>
                    <img src="assets/images/paypal_logo.png" />
                  </div>
                </mat-radio-button>
              }
            </mat-radio-group>
          </div>
        }
      </div>
      <portal-tos-applied></portal-tos-applied>
      <div class="paypal-button-wrapper">
        @if (radioGroup?.value === 'paypal') {
          <div
            class="paypal-button-container"
            portalPayPalButton
            [checkoutComponent]="checkoutComponent"
            (loading$)="loadingService.isLoading$.next($event)"
          ></div>
        }
        @if ((payPalService.showPayPalLoading$ | async) === true) {
          <div class="progress-spinner-wrapper">
            <mat-progress-spinner mode="indeterminate" color="accent" diameter="30"></mat-progress-spinner>
          </div>
        }
      </div>
      <ng-container>
        @if (createAsOffer) {
          <button
            mat-raised-button
            color="primary"
            class="chunky-button"
            [attr.data-test-selector]="'requestOfferButton'"
            (click)="requestOffer()"
            [disabled]="
              (loadingService.isLoading$ | async) === true ||
              (selectedInvoiceAddress$ | async) === undefined ||
              (selectedDeliveryAddress$ | async) === undefined ||
              ((cartService.cart$ | async).CartRequiresLicenseAddress && !(cartService.cart$ | async).CartLicenseAddress)
            "
            [attr.data-unit-test-selector]="'request-offer'"
          >
            {{ translationService.translations.labels.RequestOffer }}
          </button>
        } @else {
          <button
            [style.pointerEvents]="radioGroup?.value === 'paypal' ? 'none' : undefined"
            mat-raised-button
            color="primary"
            class="chunky-button"
            [attr.data-test-selector]="'buyNowButton'"
            (click)="finishOrder()"
            [disabled]="
              (loadingService.isLoading$ | async) === true ||
              (selectedInvoiceAddress$ | async) === undefined ||
              (selectedDeliveryAddress$ | async) === undefined ||
              checkoutForm.get('deliveryDateGroup')?.valid === false ||
              ((cartService.cart$ | async).CartRequiresLicenseAddress && !(cartService.cart$ | async).CartLicenseAddress)
            "
            [attr.data-unit-test-selector]="'order-cart'"
          >
            {{ translationService.translations.labels.BuyNow }}
          </button>
        }
      </ng-container>
    </div>
  </div>
</div>
