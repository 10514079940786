import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CartPriceDetailsComponent } from './cart-price-details.component';

@NgModule({
  declarations: [CartPriceDetailsComponent],
  imports: [CommonModule],
  exports: [CartPriceDetailsComponent],
})
export class CartPriceDetailsModule {}
