<mat-form-field class="hide-form-changes" color="accent">
  <mat-label>{{ translationService.translations.feedback.TextLabel }}</mat-label>
  <textarea
    tabIndex="0"
    matInput
    [maxLength]="maxLength"
    [formControl]="$any(feedbackService).feedbackForm?.controls['FeedbackText']"
    cdkTextareaAutosize
    cdkAutosizeMinRows="3"
  ></textarea>
  @if (feedbackService.feedbackForm.controls['FeedbackText'].value?.length >= maxLength) {
    <mat-hint>{{ translationService.translations.error.MaxLengthReached }}</mat-hint>
  }
</mat-form-field>
