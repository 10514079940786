<portal-base-dialog
  (primaryAction)="sendFeedback()"
  [portalIcon]="dialogData.config?.headerIcon || 'enthus-feedback'"
  [header]="dialogData.config?.dialogHeaderText || feedbackComponent.translationService.translations.information.SendFeedback.toString()"
  [confirmButtonDescription]="feedbackComponent.translationService.translations.action.Send.toString()"
  [isConfirmButtonDisabled]="feedbackComponent.feedbackService?.feedbackForm.invalid || (loadingService.isLoading$ | async) === true"
>
  <div class="base-dialog-content">
    <portal-feedback
      [infoText]="dialogData.config?.feedbackInfoText"
      [infoSubtext]="dialogData.config?.feedbackInfoSubtext"
      [showAlternativeContactOptions]="dialogData.config?.showAlternativeContactOptions"
      #feedbackComponent="portal-feedback"
    ></portal-feedback>
  </div>
</portal-base-dialog>
