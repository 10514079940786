import { Component, EventEmitter, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DropdownValuesService } from '../../services/dropdown-values.service';
import { FeedbackService } from '../../services/feedback.service';
import { LoadingService } from '../../services/loading.service';
import { catchError, finalize, take, takeUntil } from 'rxjs/operators';
import { IFeedbackConfig } from '../../interfaces/feedback.interface';
import { EMPTY } from 'rxjs';

@Component({
  selector: 'portal-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.scss'],
})
export class FeedbackDialogComponent implements OnDestroy {
  private static _feedbackDialogRef: MatDialogRef<FeedbackDialogComponent>;
  private _destroyEmitter = new EventEmitter<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: { config: IFeedbackConfig },
    private _dropdownValuesService: DropdownValuesService,
    public loadingService: LoadingService,
    private _feedbackService: FeedbackService
  ) {
    if (!this._dropdownValuesService.dropdownValues$.value) {
      this._dropdownValuesService.initializeDropdownValues();
    }
  }

  public static closeDialog(): void {
    FeedbackDialogComponent._feedbackDialogRef?.close();
  }

  public static openFeedbackDialog(feedbackService: FeedbackService, matDialog: MatDialog, config?: IFeedbackConfig): void {
    FeedbackDialogComponent.closeDialog();

    FeedbackDialogComponent._feedbackDialogRef = matDialog.open(FeedbackDialogComponent, {
      hasBackdrop: true,
      autoFocus: true,
      data: {
        config: config,
        feedbackService: this,
      },
    });

    if (config?.feedbackCategoryId) {
      feedbackService.feedbackForm.controls.FeedbackCategory.setValue(config.feedbackCategoryId);
    }

    this._feedbackDialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => feedbackService.feedbackForm.reset());
  }

  public sendFeedback(): void {
    let occurredError;

    this._feedbackService
      .sendFeedback({})
      .pipe(
        takeUntil(this._destroyEmitter),
        catchError((error) => {
          occurredError = error;

          return EMPTY;
        }),
        finalize(() => {
          if (!occurredError) {
            FeedbackDialogComponent.closeDialog();
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroyEmitter.next();
  }
}
