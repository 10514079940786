import { Component } from '@angular/core';
import { TranslationService } from '../../services/translation.service';

@Component({
  selector: 'portal-thanks-for-feedback-dialog',
  templateUrl: './thanks-for-feedback-dialog.component.html',
  styleUrls: ['./thanks-for-feedback-dialog.component.scss'],
})
export class ThanksForFeedbackDialogComponent {
  constructor(public translationService: TranslationService) {}
}
