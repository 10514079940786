import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BehaviorSubject, EMPTY, mergeWith, Observable } from 'rxjs';
import { catchError, filter, finalize, take, takeUntil, tap } from 'rxjs/operators';
import { ThanksForFeedbackDialogComponent } from '../feedback/thanks-for-feedback-dialog/thanks-for-feedback-dialog.component';
import { DropdownValuesService } from './dropdown-values.service';
import { TranslationService } from './translation.service';
import { Utilities } from '../utilities';
import { UserService } from './user.service';
import { PortalFormGroup } from '../danger-zone/form-group-override';
import { PortalFormControl } from '../danger-zone/form-control-override';
import { LoadingService } from './loading.service';
import { IOnboarding, ISendFeedbackOptions } from '../interfaces/feedback.interface';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  public feedbackForm: PortalFormGroup;
  public feedbackSubject$: BehaviorSubject<string> = new BehaviorSubject<string>('Technical problem');

  constructor(
    private _httpClient: HttpClient,
    private _matDialog: MatDialog,
    private _matSnackBar: MatSnackBar,
    private _router: Router,
    private _translationService: TranslationService,
    private _dropdownValuesService: DropdownValuesService,
    private _userService: UserService,
    public loadingService: LoadingService
  ) {}

  public resetFeedback(destroyEmitter: EventEmitter<void>): void {
    this.feedbackForm = new PortalFormGroup({
      FeedbackCategory: new PortalFormControl(destroyEmitter, 1, Validators.required),
      FeedbackText: new PortalFormControl(destroyEmitter, '', Validators.required),
      FeedbackEMailAddress: new PortalFormControl(destroyEmitter, '', Utilities.emailValidator),
    });

    this.feedbackForm.controls.FeedbackCategory.valueChanges
      .pipe(
        takeUntil(destroyEmitter),
        filter((category) => category && category > 0),
        tap((category) => {
          const localizedCategoryDescription = this._dropdownValuesService.dropdownValues$.value.FeedbackCategories.find(
            (feedbackCategory) => feedbackCategory.FeedbackCategoryId === category
          ).FeedbackCategoryDescription[this._translationService.locale];

          this.feedbackSubject$.next(localizedCategoryDescription.toString());
        })
      )
      .subscribe();
  }

  public sendFeedback({ feedback = this.feedbackForm.value, action, showBackdrop }: ISendFeedbackOptions): Observable<void> {
    this.loadingService.isLoading$.next(true);
    this.feedbackForm.disable();

    feedback.FeedbackCategory = this._dropdownValuesService.dropdownValues$.value.FeedbackCategories.find(
      (feedbackCategory) => feedbackCategory.FeedbackCategoryId === this.feedbackForm.value.FeedbackCategory
    ).FeedbackCategoryDescription[this._translationService.locale];

    let occurredError: HttpErrorResponse;

    return this._httpClient.post<void>('api/feedback', feedback).pipe(
      finalize(() => {
        this.loadingService.isLoading$.next(false);
        this.feedbackForm.enable();
        this.feedbackForm.markAsPristine();

        const message: string = occurredError
          ? this._translationService.translations.feedback.FeedbackSendFailure.toString()
          : this._translationService.translations.feedback.FeedbackSendSuccess.toString();
        this._matSnackBar.open(message, null, { duration: 5000 });

        if (!occurredError) {
          this.feedbackForm.reset();

          const matDialog: MatDialogRef<ThanksForFeedbackDialogComponent> = this._matDialog.open(ThanksForFeedbackDialogComponent, {
            hasBackdrop: showBackdrop,
          });

          matDialog
            .backdropClick()
            .pipe(
              mergeWith(matDialog.beforeClosed()),
              takeUntil(matDialog.afterClosed()),
              take(1),
              tap(() => {
                if (action) {
                  action();
                }
              })
            )
            .subscribe();
        }
      }),
      catchError((error) => {
        occurredError = error;
        return EMPTY;
      })
    );
  }

  public requestOnboarding(onboarding: IOnboarding): Observable<void> {
    return this._httpClient.post<void>('api/feedback/onboarding', onboarding);
  }

  public declineOnboarding(): Observable<void> {
    return this._httpClient.post<void>('api/feedback/onboarding/nointerest', null);
  }
}
