<portal-dev-navigation></portal-dev-navigation>
<portal-breadcrumbs></portal-breadcrumbs>
@if ((viewIsReady$ | async) === true) {
  <portal-base-cart>
    <ng-container class="additional-actions">
      @if ((checkoutAllowed$ | async) === true && (authenticationService.isAuthenticated$ | async) === true) {
        <button
          [disabled]="(loadingService.isLoading$ | async) === true || baseCartComponent.cartPositionsForm.invalid"
          (click)="goToCheckout()"
          color="primary"
          mat-raised-button
          [attr.data-unit-test-selector]="'checkout-order'"
        >
          <mat-icon [svgIcon]="'enthus-shopping_cart'"></mat-icon>&nbsp;{{ translationService.translations.action.Checkout }}
        </button>
      }
      @if ((authenticationService.isAuthenticated$ | async) === true) {
        <button (click)="goToCreateOffer()" [attr.data-unit-test-selector]="'checkout-offer'" color="accent" mat-raised-button>
          <mat-icon svgIcon="enthus-offers"></mat-icon>&nbsp;{{ translationService.translations.action.CreateOffer }}
        </button>
      } @else {
        <button (click)="loginToContinue()" color="accent" mat-raised-button [attr.data-unit-test-selector]="'login-to-continue'">
          {{ translationService.translations.action.LoginToContinue }}
        </button>
      }
    </ng-container>
  </portal-base-cart>
}
