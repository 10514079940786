import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { PayPalService } from '../services/paypal/paypal.service';
import { CheckoutComponent } from '../cart/checkout/checkout.component';

@Directive({
  selector: '[portalPayPalButton]',
})
export class PayPalButtonDirective implements AfterViewInit {
  @Output() loading$: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() paymentStarted: EventEmitter<void> = new EventEmitter<void>();
  @Output() paymentCancelled: EventEmitter<void> = new EventEmitter<void>();
  @Input() checkoutComponent: CheckoutComponent;

  constructor(
    private _elementRef: ElementRef<HTMLElement>,
    private _payPalService: PayPalService
  ) {
    this.paymentStarted = this._payPalService.paymentStarted;
    this.paymentCancelled = this._payPalService.paymentCancelled;
  }

  public ngAfterViewInit(): void {
    this.renderButton()
      .then(() => {
        this._payPalService.showPayPalLoading$.next(false);
        return null;
      })
      .catch(() => null);
  }

  public renderButton(): Promise<void> {
    return this._payPalService.renderButton(this._elementRef, this.checkoutComponent.orderCart.bind(this.checkoutComponent));
  }
}
