<portal-base-dialog
  [portalIcon]="'enthus-favorite'"
  [header]="translationService.translations.information.ThankYou.toString()"
  [hideDismissButton]="true"
  (primaryAction)="baseDialogComponent.dismiss()"
  #baseDialogComponent
  [contentPadding]="false"
>
  <div class="base-dialog-content">
    <portal-marker-sketch [infoMessage]="translationService.translations.information.ThanksForFeedback.toString()" [svgIcon]="'enthus-favorite'">
      <pre>{{ translationService.translations.information.YourFeedbackIsImportant }}</pre>
    </portal-marker-sketch>
  </div>
</portal-base-dialog>
