import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioGroup } from '@angular/material/radio';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, EMPTY, fromEvent, merge, Observable, of } from 'rxjs';
import { catchError, concatMap, debounceTime, distinctUntilChanged, filter, finalize, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ICart, IOrderCartResponse } from '../../interfaces/cart.interface';
import { AddressService, IAddress, ISetAddressData } from '../../services/address.service';
import { AppService } from '../../services/app.service';
import { BaseCartService } from '../../services/base-cart.service';
import { CheckoutService } from '../../services/checkout.service';
import { OverlayService } from '../../services/overlay.service';
import { PayPalService } from '../../services/paypal/paypal.service';
import { TranslationService } from '../../services/translation.service';
import { UserService } from '../../services/user.service';
import { ErrorHandlingService } from '../../services/error-handling.service';
import { SelectAddressDialogComponent } from '../../addresses/select-address-dialog/select-address-dialog.component';
import { PortalFormControl } from '../../danger-zone/form-control-override';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { PortalFormGroup } from '../../danger-zone/form-group-override';
import { DELIVERY_DATE_AND_DATETYPE_VALIDATOR } from '../../custom-validators';
import { SentryService } from '../../services/sentry.service';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'portal-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
  providers: [{ provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false } }],
})
export class CheckoutComponent implements OnInit, OnDestroy, AfterViewInit {
  public selectedInvoiceAddress$: BehaviorSubject<IAddress> = new BehaviorSubject<IAddress>(null);
  public selectedDeliveryAddress$: BehaviorSubject<IAddress> = new BehaviorSubject<IAddress>(null);
  public deliveryAddressAdditionalInformationSaved = false;
  public invoiceAddressAdditionalInformationSaved = false;
  public createAsOffer = false;
  public additionalInformationLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showPositionRemarkColumn: boolean;
  private _destroyEmitter: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('radioGroup') public radioGroup: MatRadioGroup;
  public checkoutComponent: CheckoutComponent = this;
  public rightColumnMinWidth = 460;
  public isRightColumnWrapped$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @ViewChild('checkoutWrapper') public checkoutWrapper: ElementRef<HTMLDivElement>;
  @ViewChild('leftColumn') public leftColumn: ElementRef<HTMLDivElement>;
  private _initiallyDisabledControls: string[] = [];

  public checkoutForm: PortalFormGroup;

  constructor(
    public translationService: TranslationService,
    public cartService: BaseCartService,
    public addressService: AddressService,
    private _matDialog: MatDialog,
    private _activatedRoute: ActivatedRoute,
    private _cd: ChangeDetectorRef,
    public overlayService: OverlayService,
    public payPalService: PayPalService,
    private _errorHandlingService: ErrorHandlingService,
    public userService: UserService,
    public appService: AppService,
    public checkoutService: CheckoutService,
    public loadingService: LoadingService
  ) {}

  public ngOnDestroy(): void {
    this.payPalService.removePayPalScript();
    this._destroyEmitter.emit();
  }

  public showOrChangeAddressDialog(address: Observable<IAddress>, addressType: 'invoice' | 'delivery'): void {
    const showAddressDialogRef = this._matDialog.open(SelectAddressDialogComponent, {
      data: { type: addressType, address: address },
      hasBackdrop: true,
    });

    showAddressDialogRef
      .afterClosed()
      .pipe(
        take(1),
        switchMap((selectedAddress: IAddress) => {
          if (selectedAddress) {
            let deliveryAddressData: ISetAddressData;
            let invoiceAddressData: ISetAddressData;

            if (addressType === 'invoice') {
              invoiceAddressData = { Address: selectedAddress, AdditionalInformationChange: false };
              this.selectedInvoiceAddress$.next(selectedAddress);

              if (this.checkoutForm.get('useInvoiceAddressAsDeliveryAddress').value) {
                deliveryAddressData = { Address: selectedAddress, AdditionalInformationChange: false };
                this.selectedDeliveryAddress$.next(selectedAddress);
              }

              if (selectedAddress.AddressName2 !== this.checkoutForm.get('invoiceAddressAdditionalInformation').value) {
                this.checkoutForm.get('invoiceAddressAdditionalInformation').setValue(selectedAddress.AddressName2, { emitEvent: false });

                if (this.checkoutForm.get('useInvoiceAddressAsDeliveryAddress').value) {
                  deliveryAddressData.Address.AddressAdditionalInformation = selectedAddress.AddressName2;
                  this.checkoutForm.get('deliveryAddressAdditionalInformation').setValue(selectedAddress.AddressName2, { emitEvent: false });
                }
              }
            } else if (addressType === 'delivery') {
              deliveryAddressData = { Address: selectedAddress, AdditionalInformationChange: false };
              this.selectedDeliveryAddress$.next(selectedAddress);

              if (selectedAddress.AddressName2 !== this.checkoutForm.get('deliveryAddressAdditionalInformation').value) {
                this.checkoutForm.get('deliveryAddressAdditionalInformation').setValue(selectedAddress.AddressName2, { emitEvent: false });
              }
            }

            return this.cartService.setAddresses(deliveryAddressData, invoiceAddressData);
          }

          return of(null);
        })
      )
      .subscribe();
  }

  public finishOrder(): void {
    this.loadingService.isLoading$.next(true);

    this.checkoutService
      .finishOrder()
      .pipe(
        takeUntil(this._destroyEmitter),
        catchError((error) => {
          error.cart = this.cartService.cart$.value;

          SentryService.captureSentryError({
            className: 'CheckoutComponent',
            errorObject: error,
            functionName: 'finishOrder',
            message: 'Failed to finish order',
          });

          return EMPTY;
        }),
        finalize(() => this.loadingService.isLoading$.next(false))
      )
      .subscribe();
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public orderCart(paypalOrderID?: string, mock_application_codes?: { mock_application_codes: string }): Observable<IOrderCartResponse> {
    this.loadingService.isLoading$.next(true);

    return this.checkoutService.orderCart(paypalOrderID, mock_application_codes).pipe(finalize(() => this.loadingService.isLoading$.next(false)));
  }

  public requestOffer(): void {
    this.loadingService.isLoading$.next(true);

    this.checkoutService
      .requestOffer()
      .pipe(
        takeUntil(this._destroyEmitter),
        finalize(() => this.loadingService.isLoading$.next(false))
      )
      .subscribe();
  }

  private _fetchAddresses(): Observable<IAddress[]> {
    return this._errorHandlingService.handleErrorWithBannerAndRetry<IAddress[]>({
      endpoint: this.addressService.getAddresses(),
      propertyModification: (addresses) => {
        const mappedAddresses = addresses.map((address) => {
          if (!address.AddressUUID && !address.AddressDescription) {
            address.AddressDescription = this.translationService.translations.common.Default.toString();
          }

          return address;
        });

        this.addressService.deliveryAddresses = mappedAddresses.filter((address) => address.AddressIsUsableForDelivery);
        this.addressService.invoiceAddresses = mappedAddresses.filter((address) => address.AddressIsUsableForInvoice);

        /**
         * existingMappedDeliveryAddress can be undefined when user do not have 'ViewAddresses' permission, therefore it is filtered out in backend
         */
        const existingMappedDeliveryAddress = mappedAddresses.find((address) => address.AddressUUID === this.cartService.cart$.value.CartDeliveryAddressUUID);
        let selectedDeliveryAddress =
          this.cartService.cart$.value.CartDeliveryAddressUUID && existingMappedDeliveryAddress ? existingMappedDeliveryAddress : mappedAddresses[0];

        /**
         * existingMappedInvoiceAddress can be undefined when user do not have 'ViewAddresses' permission, therefore it is filtered out in backend
         */
        const existingMappedInvoiceAddress = mappedAddresses.find((address) => address.AddressUUID === this.cartService.cart$.value.CartInvoiceAddressUUID);
        let selectedInvoiceAddress =
          this.cartService.cart$.value.CartInvoiceAddressUUID && existingMappedInvoiceAddress ? existingMappedInvoiceAddress : mappedAddresses[0];

        if (selectedDeliveryAddress) {
          /**
           * assurance that customer address (default) is used when user do not have 'ViewAddresses' permission
           */
          if (!this.userService.currentUser$?.value?.UserPermissionNames.includes('ViewAddresses')) {
            selectedDeliveryAddress = mappedAddresses[0];
          }
          if (!this.checkoutForm.get('deliveryAddressAdditionalInformation').value) {
            this.checkoutForm.get('deliveryAddressAdditionalInformation').setValue(selectedDeliveryAddress.AddressName2, { emitEvent: false });
          }
        }
        if (selectedInvoiceAddress) {
          /**
           * assurance that customer address (default) is used when user do not have 'ViewAddresses' permission
           */
          if (!this.userService.currentUser$?.value?.UserPermissionNames.includes('ViewAddresses')) {
            selectedInvoiceAddress = mappedAddresses[0];
          }
          if (!this.checkoutForm.get('invoiceAddressAdditionalInformation').value) {
            this.checkoutForm.get('invoiceAddressAdditionalInformation').setValue(selectedInvoiceAddress.AddressName2, { emitEvent: false });
          }
        }

        this.selectedDeliveryAddress$.next(selectedDeliveryAddress);
        this.selectedInvoiceAddress$.next(selectedInvoiceAddress);
      },
      bannerTitle: this.translationService.translations.error.FetchAddressesFailed.toString(),
    });
  }

  public useInvoiceAddressAsDeliveryAddressChange(checkboxChange: MatCheckboxChange): void {
    const selectedInvoiceAddress: IAddress = this.selectedInvoiceAddress$.value;
    selectedInvoiceAddress.AddressAdditionalInformation = this.checkoutForm.get('invoiceAddressAdditionalInformation').value;

    this.cartService
      .setCartInvoiceAddressAsDeliveryAddress(checkboxChange.checked)
      .pipe(
        takeUntil(this._destroyEmitter),
        tap((cart: ICart) => {
          if (cart.UseInvoiceAddressAsDeliveryAddress) {
            this.checkoutForm.get('deliveryAddressAdditionalInformation').setValue(this.checkoutForm.get('invoiceAddressAdditionalInformation').value, {
              emitEvent: false,
            });
            this.checkoutForm.get('deliveryAddressAdditionalInformation').disable({ emitEvent: false });
            this.selectedDeliveryAddress$.next(selectedInvoiceAddress);
          } else {
            this.checkoutForm.get('deliveryAddressAdditionalInformation').enable({ emitEvent: false });
          }
          this.checkoutForm.get('useInvoiceAddressAsDeliveryAddress').markAsPristine();
        })
      )
      .subscribe();
  }

  private _isRightColumnWrapped(): boolean {
    return this.checkoutWrapper?.nativeElement.offsetWidth - this.leftColumn?.nativeElement.offsetWidth < this.rightColumnMinWidth;
  }

  public ngAfterViewInit(): void {
    this.isRightColumnWrapped$.next(this._isRightColumnWrapped());
    this._cd.detectChanges();
  }

  private _listenForAdditionalInformationValuesChanges(): void {
    merge(
      this.checkoutForm.get('deliveryAddressAdditionalInformation').valueChanges.pipe(
        debounceTime(500),
        filter((value) => value !== this.cartService.cart$.value.CartDeliveryAddressAdditionalInformation),
        map((value) => ({ type: 'delivery', text: value }))
      ),
      this.checkoutForm.get('invoiceAddressAdditionalInformation').valueChanges.pipe(
        debounceTime(500),
        filter((value) => value !== this.cartService.cart$.value.CartInvoiceAddressAdditionalInformation),
        map((value) => ({ type: 'invoice', text: value }))
      )
    )
      .pipe(
        takeUntil(this._destroyEmitter),
        concatMap((addressAdditionalInformationChange: { type: 'delivery' | 'invoice'; text: string }) => {
          this.additionalInformationLoading$.next(true);

          if (addressAdditionalInformationChange.type === 'delivery') {
            this.checkoutForm.get('deliveryAddressAdditionalInformation').markAsTouched();
          } else if (addressAdditionalInformationChange.type === 'invoice') {
            this.checkoutForm.get('invoiceAddressAdditionalInformation').markAsTouched();
          }

          let deliveryAddressData: ISetAddressData;
          let invoiceAddressData: ISetAddressData;

          if (addressAdditionalInformationChange.type === 'invoice') {
            invoiceAddressData = { Address: this.selectedInvoiceAddress$.value, AdditionalInformationChange: true };
            invoiceAddressData.Address.AddressAdditionalInformation = addressAdditionalInformationChange.text;
          }

          if (
            addressAdditionalInformationChange.type === 'delivery' ||
            (addressAdditionalInformationChange.type === 'invoice' && this.checkoutForm.get('useInvoiceAddressAsDeliveryAddress').value)
          ) {
            deliveryAddressData = { Address: this.selectedDeliveryAddress$.value, AdditionalInformationChange: true };
            deliveryAddressData.Address.AddressAdditionalInformation = addressAdditionalInformationChange.text;
          }

          return this._errorHandlingService.handleErrorWithBannerAndRetry({
            endpoint: this.cartService.setAddresses(deliveryAddressData, invoiceAddressData).pipe(
              finalize(() => {
                if (!this.checkoutForm.get('deliveryAddressAdditionalInformation').invalid)
                  this.checkoutForm.get('deliveryAddressAdditionalInformation').markAsPristine();
                if (!this.checkoutForm.get('invoiceAddressAdditionalInformation').invalid)
                  this.checkoutForm.get('invoiceAddressAdditionalInformation').markAsPristine();

                this._setAdditionalInformationSaved(addressAdditionalInformationChange.type);

                this.additionalInformationLoading$.next(false);
                if (addressAdditionalInformationChange.type === 'invoice' && this.checkoutForm.get('useInvoiceAddressAsDeliveryAddress').value) {
                  this.checkoutForm.get('deliveryAddressAdditionalInformation').setValue(addressAdditionalInformationChange.text, { emitEvent: false });
                }
              })
            ),
            propertyModification: null,
            bannerTitle:
              this.translationService.translations.error.SaveAddressAdditionalInformation.toString() +
              '. ' +
              this.translationService.translations.action.TryAgain.toString(),
          });
        })
      )
      .subscribe();
  }

  private _setAdditionalInformationSaved(type: string): void {
    this.deliveryAddressAdditionalInformationSaved = type === 'delivery' ? true : this.deliveryAddressAdditionalInformationSaved;
    this.invoiceAddressAdditionalInformationSaved = type === 'invoice' ? true : this.invoiceAddressAdditionalInformationSaved;
  }

  ngOnInit(): void {
    this.checkoutForm = new PortalFormGroup({
      deliveryAddressAdditionalInformation: new PortalFormControl(this._destroyEmitter, this.cartService.cart$.value.CartDeliveryAddressAdditionalInformation),
      invoiceAddressAdditionalInformation: new PortalFormControl(this._destroyEmitter, this.cartService.cart$.value.CartInvoiceAddressAdditionalInformation),
      useInvoiceAddressAsDeliveryAddress: new PortalFormControl(this._destroyEmitter, this.cartService.cart$.value.UseInvoiceAddressAsDeliveryAddress),
      checkoutDetails: new PortalFormGroup({
        description: new PortalFormControl(this._destroyEmitter, this.cartService.cart$.value.CartDescription),
        referenceNo: new PortalFormControl(this._destroyEmitter, this.cartService.cart$.value.CartReference),
        remark: new PortalFormControl(this._destroyEmitter, this.cartService.cart$.value.CartRemark),
        deliveryDateGroup: new PortalFormGroup(
          {
            deliveryDateTypeControl: new PortalFormControl(this._destroyEmitter, this.cartService.cart$.value.CartDeliveryDateTypeID),
            deliveryDateControl: new PortalFormControl(this._destroyEmitter, this.cartService.cart$.value.CartDeliveryDate),
          },
          [DELIVERY_DATE_AND_DATETYPE_VALIDATOR('deliveryDateTypeControl', 'deliveryDateControl')]
        ),
      }),
      usePartialDelivery: new PortalFormControl(this._destroyEmitter, this.cartService.cart$.value.UsePartialDelivery),
    });

    this.loadingService.isLoading$
      .pipe(
        takeUntil(this._destroyEmitter),
        distinctUntilChanged(),
        tap((isLoading) => this.checkoutService.restoreFormState(isLoading, this.checkoutForm, this._initiallyDisabledControls))
      )
      .subscribe();

    fromEvent(window, 'resize')
      .pipe(
        takeUntil(this._destroyEmitter),
        tap(() => this.isRightColumnWrapped$.next(this._isRightColumnWrapped()))
      )
      .subscribe();

    if (this._activatedRoute.snapshot.queryParams && this._activatedRoute.snapshot.queryParams.document === 'offer') this.createAsOffer = true;

    this.selectedDeliveryAddress$
      .pipe(
        takeUntil(this._destroyEmitter),
        tap((selectedDeliveryAddress) => (this.payPalService.deliveryAddress = selectedDeliveryAddress))
      )
      .subscribe();

    this.cartService.cart$
      .pipe(
        takeUntil(this._destroyEmitter),
        tap((cart) => (this.showPositionRemarkColumn = cart.CartPositions.some((cartPosition) => cartPosition.PositionCustomerRemark)))
      )
      .subscribe();

    if (this.cartService.cart$.value.UseInvoiceAddressAsDeliveryAddress)
      this.checkoutForm.get('deliveryAddressAdditionalInformation').disable({ emitEvent: false });

    this._fetchAddresses().pipe(takeUntil(this._destroyEmitter)).subscribe();
    this._listenForAdditionalInformationValuesChanges();
  }
}
