<div class="breadcrumbs">
  <a [href]="location.origin">
    <span>Home</span>
  </a>
  @for (breadcrumb of breadcrumbService.breadcrumbs$ | async; track $index) {
    <div>
      <span class="double-quotation-mark">&#187;</span>
      <a (click)="navigateToBreadcrumb(breadcrumb.link)" [attr.data-text]="breadcrumb.description[translationService.locale]">
        <span>{{ breadcrumb.description[translationService.locale] }}</span>
      </a>
    </div>
  }
</div>
